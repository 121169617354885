import PublicIcon from '@mui/icons-material/Public';
import TwitterIcon from '@mui/icons-material/Twitter';
import Stack from '@mui/material/Stack';
import { Author } from '../../types';
import { AuthorSocialListItem } from './AuthorSocialListItem';
import { ReactComponent as MastodonIcon } from './mastodon.svg';

export type AuthorSocialListProps = {
  author?: Author | null;
};

export function AuthorSocialList(props: AuthorSocialListProps) {
  if (!props.author) return null;
  return (
    <Stack spacing={1}>
      <AuthorSocialListItem icon={<PublicIcon />} href={props.author.url} translationId="author.website" />
      <AuthorSocialListItem icon={<TwitterIcon />} href={props.author.twitter} translationId="author.twitter" />
      <AuthorSocialListItem icon={<MastodonIcon />} href={props.author.mastodon} translationId="author.mastodon" />
    </Stack>
  );
}
