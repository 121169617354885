import styled from '@emotion/styled';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import { Application } from '../../types';
import { Icon } from '../Manifest/Icon';

const ApplicationContainer = styled(Box)`
  display: flex;
  text-align: start;
  column-gap: 0.5rem;
  align-items: center;
`;
const IconContainer = styled('div')`
  max-width: 64px;
  min-width: 64px;
  max-height: 64px;
  min-height: 64px;
  border-radius: 18px;
  overflow: hidden;
  @supports (clip-path: inset(0)) or (-webkit-clip-path: inset(0)) {
    border-radius: 0px;
    clip-path: inset(6.36% round 11px);
  }
`;
const TextContainer = styled(Box)`
  display: flex;
  flex-direction: column;
  text-align: start;
`;

export type ListItemProps = {
  application: Application;
  hideDescription?: boolean;
  direction?: 'horizontal' | 'vertical';
};

export function ApplicationListItem(props: ListItemProps) {
  const isHorizontallyDisplayed = props.direction !== 'vertical';

  return (
    <ApplicationContainer
      flexDirection={isHorizontallyDisplayed ? 'row' : 'column'}
      sx={{ px: 0, maxWidth: !isHorizontallyDisplayed ? 96 : 'unset' }}
    >
      <IconContainer>
        <Icon icons={props.application.icons} alt="" baseUrl={props.application.applicationUrl} />
      </IconContainer>
      <TextContainer sx={{ flexGrow: '1' }}>
        <Stack direction="column">
          <Typography
            variant={isHorizontallyDisplayed ? 'body1' : 'caption'}
            sx={{ textAlign: isHorizontallyDisplayed ? 'start' : 'center' }}
          >
            {props.application.name}
          </Typography>
          {props.hideDescription ? null : (
            <Typography variant="caption">{props.application.shortDescription}</Typography>
          )}
        </Stack>
      </TextContainer>
    </ApplicationContainer>
  );
}
