import styled from '@emotion/styled';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { Author } from '../../types';

const AuthorIcon = styled('img')`
  width: 64px;
  height: 64px;
  border-radius: 32px;
`;

const AuthorContainer = styled(Box)`
  display: flex;
  flex-direction: row;
  text-align: start;
  column-gap: 0.5rem;
  align-items: center;
`;
const IconContainer = styled(Box)`
  max-width: 64px;
  min-width: 64px;
  max-height: 64px;
  min-height: 64px;
`;
const TextContainer = styled(Box)`
  display: flex;
  flex-direction: column;
  text-align: start;
`;

export type AuthorItemProps = {
  author: Author;
};

export function AuthorItem(props: AuthorItemProps) {
  return (
    <AuthorContainer>
      <IconContainer>
        <AuthorIcon src={props.author.picture?.url || '/logo192.png'} alt={props.author.name + "'s profile picture"} />
      </IconContainer>
      <TextContainer sx={{ flexGrow: '1' }}>
        <Typography variant="body1">{props.author.name}</Typography>
      </TextContainer>
    </AuthorContainer>
  );
}
