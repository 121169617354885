import styled from '@emotion/styled';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import LinearProgress from '@mui/material/LinearProgress';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import { useCallback } from 'react';
import { FormattedMessage } from 'react-intl';
import { useNavigate } from 'react-router-dom';

const AppProgress = styled(LinearProgress)``;

export type AppTitleProps = {
  loading?: boolean;
  back?: string;
};
export function AppTitle(props: AppTitleProps) {
  const navigate = useNavigate();
  const onBackButtonClicked = useCallback(
    () => props.back && navigate(props.back, { replace: true }),
    [navigate, props.back]
  );
  return (
    <Box sx={{ marginBottom: 2 }}>
      <AppBar>
        <Toolbar>
          {props.back ? (
            <IconButton
              size="large"
              edge="start"
              color="inherit"
              aria-label="menu"
              sx={{ mr: 1 }}
              onClick={onBackButtonClicked}
            >
              <ArrowBackIcon />
            </IconButton>
          ) : null}
          <Typography variant="h1" noWrap component="div" sx={{ flexGrow: 1 }}>
            <FormattedMessage id="app.title" />
          </Typography>
        </Toolbar>
      </AppBar>
      <Toolbar />
      {props.loading ? <AppProgress /> : null}
    </Box>
  );
}
