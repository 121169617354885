import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import Container from '@mui/material/Container';
import Divider from '@mui/material/Divider';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import { useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import ApplicationService from '../../services/ApplicationService';
import { Application, Author } from '../../types';
import { AuthorDetails } from '../Author/AuthorDetails';
import { ApplicationList } from './ApplicationList';
import { ApplicationListItem } from './ApplicationListItem';

export type ApplicationDetailsProps = {
  application?: Application | null;
  author?: Author | null;
};

export function ApplicationDetails(props: ApplicationDetailsProps) {
  const [dbAuthorApplications, setAuthorApplications] = useState<Application[]>([]);
  const [isApplicationLoading, setApplicationLoading] = useState<boolean>(false);

  const authorId = props.author?.id;
  const dbApplication = props.application;
  const appId = props.application?.id;
  useEffect(
    function () {
      if (!authorId) {
        setAuthorApplications([]);
        return;
      }
      setApplicationLoading(true);
      ApplicationService.findAllByAuthor(authorId)
        .then(function (applications) {
          setAuthorApplications(applications.filter((app) => app.id !== appId));
          setApplicationLoading(false);
        })
        .catch((err) => {
          console.warn(err);
          setApplicationLoading(false);
        });
    },
    [authorId, appId]
  );

  if (!dbApplication) return null;
  return (
    <Stack spacing={2} sx={{ px: 2 }}>
      <ApplicationListItem application={dbApplication} hideDescription />
      <Button href={dbApplication.applicationUrl} target="_blank" variant="contained" color="success">
        <FormattedMessage id="app.cta" />
      </Button>
      <Typography variant="body2" sx={{ textAlign: 'start' }}>
        {dbApplication.description || dbApplication.shortDescription}
      </Typography>
      <Box
        sx={{
          display: dbApplication.screenshots.length > 0 ? 'flex' : 'none',
          flexDirection: 'row',
          columnGap: '1em',
          maxWidth: '100%',
          overflowX: 'auto',
          overflowY: 'none'
        }}
      >
        {dbApplication.screenshots.map(function (picture, index) {
          return (
            <img
              key={index}
              src={(dbApplication.applicationUrl || '') + picture.src}
              alt={picture.label || ''}
              loading={index > 1 ? 'lazy' : 'eager'}
              style={{ minWidth: '80%', aspectRatio: '240/320', pointerEvents: 'none', objectFit: 'contain' }}
            />
          );
        })}
      </Box>
      <Divider />
      <Typography variant="h2">
        <FormattedMessage id="app.section.author" />
      </Typography>
      <AuthorDetails author={props.author} />
      {isApplicationLoading ? (
        <Container>
          <CircularProgress />
        </Container>
      ) : null}
      {dbAuthorApplications.length > 0 ? (
        <>
          <Typography variant="h2">
            <FormattedMessage id="app.section.author-games" />
          </Typography>
          <ApplicationList applications={dbAuthorApplications} direction="horizontal" />
        </>
      ) : null}
    </Stack>
  );
}
