import { PaletteMode } from '@mui/material';
import createTheme from '@mui/material/styles/createTheme';

export const generateTheme = function (mode: PaletteMode) {
  return createTheme({
    palette: {
      mode,
      primary: {
        main: '#8547b2'
      },
      success: {
        main: '#8547b2',
        contrastText: '#FFF'
      }
    },
    typography: {
      h1: {
        fontSize: '1.3rem',
        fontWeight: 'bold',
        fontVariant: 'small-caps',
        textAlign: 'start'
      },
      h2: {
        fontSize: '1.2rem',
        fontWeight: 'bold',
        textAlign: 'start'
      },
      h3: {
        fontSize: '1.1rem',
        fontWeight: 'bold',
        textAlign: 'start'
      },
      h4: {
        fontSize: '1rem',
        fontWeight: 'bold',
        textAlign: 'start'
      },
      h5: {
        fontSize: '0.9rem',
        fontWeight: 'bold',
        textAlign: 'start'
      },
      h6: {
        fontSize: '0.9rem',
        fontWeight: 'bold',
        textAlign: 'start'
      },
      body1: {
        fontWeight: 'bold',
        textAlign: 'start'
      },
      body2: {
        textAlign: 'start'
      },
      caption: {
        textAlign: 'start'
      }
    },
    components: {
      MuiLink: {
        styleOverrides: {
          root: ({ theme }) => {
            return {
              textDecoration: 'none',
              color: theme.palette.text.primary
            };
          }
        }
      }
    }
  });
};
