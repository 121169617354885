import { useMemo } from 'react';
import { ManifestIcon } from '../../types';
import { parseSizes } from './helpers';

export type ManifestIconProps = {
  icons: ManifestIcon[] | undefined;
  baseUrl?: string;
  alt: string;
};

export function Icon(props: ManifestIconProps) {
  const icon = useMemo(
    function () {
      const icons = props.icons || [];
      const iconBySize = icons.reduce<Record<string, ManifestIcon>>(function (record, icon) {
        const sizes = parseSizes(icon.sizes);
        for (const size of sizes) {
          const key = `${size.width}x${size.height}`;
          if (icon.purpose === 'monochrome' || record[key]?.purpose === 'maskable') continue;
          record[key] = icon;
        }
        return record;
      }, {});
      return iconBySize['192x192'] || iconBySize['128x128'] || iconBySize['64x64'] || null;
    },
    [props.icons]
  );

  const imgSrc = useMemo(
    function () {
      if (icon?.src) return `${props.baseUrl || ''}${icon.src}`.trim();
      return 'https://placehold.co/192x192';
    },
    [icon.src, props.baseUrl]
  );

  return <img src={imgSrc} alt={props.alt} width="64" height="64" />;
}
