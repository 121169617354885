import Typography from '@mui/material/Typography';
import { useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { AppContainer } from '../../components/AppContainer';
import { AppTitle } from '../../components/AppTitle';
import { ApplicationList } from '../../components/Application/ApplicationList';
import ApplicationService from '../../services/ApplicationService';
import { Application } from '../../types';

export function HomePage() {
  const [applications, setApplications] = useState<Application[]>([]);
  const [isLoading, setLoading] = useState(false);

  useEffect(function () {
    ApplicationService.findAll()
      .then((apps) => {
        setApplications(apps);
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
        console.warn(err);
      });
  }, []);

  return (
    <AppContainer>
      <AppTitle loading={isLoading} />
      <Typography variant="h2" sx={{ my: 1, mx: 2 }}>
        <FormattedMessage id="apps.latest-releases" />
      </Typography>
      <ApplicationList applications={applications} />
    </AppContainer>
  );
}
