import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { AppContainer } from '../../components/AppContainer';
import { AppTitle } from '../../components/AppTitle';
import { ApplicationDetails } from '../../components/Application/ApplicationDetails';
import ApplicationService from '../../services/ApplicationService';
import AuthorService from '../../services/AuthorService';
import { Application, Author } from '../../types';

export function AppItem() {
  const { id: appId } = useParams();
  const [dbApplication, setDbApplication] = useState<Application | null>(null);
  const [dbAuthor, setDbAuthor] = useState<Author | null>(null);
  const [isApplicationLoading, setApplicationLoading] = useState<boolean>(false);
  const [isAuthorLoading, setAuthorLoading] = useState<boolean>(false);

  useEffect(
    function () {
      if (!appId) return;
      setApplicationLoading(true);
      ApplicationService.findOne(appId)
        .then((application) => {
          setDbApplication(application);
          setApplicationLoading(false);
        })
        .catch((err) => {
          console.warn(err);
          setApplicationLoading(false);
        });
    },
    [appId]
  );

  useEffect(
    function () {
      if (!dbApplication?.authorId) return;
      setAuthorLoading(true);
      AuthorService.findOne(dbApplication.authorId)
        .then((author) => {
          setDbAuthor(author);
          setAuthorLoading(false);
        })
        .catch((err) => {
          console.warn(err);
          setAuthorLoading(false);
        });
    },
    [dbApplication]
  );

  return (
    <AppContainer>
      <AppTitle loading={isApplicationLoading || isAuthorLoading} back="/" />
      <ApplicationDetails application={dbApplication} author={dbAuthor} />
    </AppContainer>
  );
}
