import styled from '@emotion/styled';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import Stack from '@mui/material/Stack';
import { Fragment } from 'react';
import { Link } from 'react-router-dom';
import { Application } from '../../types';
import { ApplicationListItem } from './ApplicationListItem';

export type ApplicationListProps = {
  applications: Application[];
  direction?: 'vertical' | 'horizontal';
};

const HorizontalApplicationListStack = styled(Stack)`
  max-width: 100%;
  overflow-x: auto;
  overflow-y: none;
`;

export function ApplicationList(props: ApplicationListProps) {
  const isVerticalList = props.direction !== 'horizontal';
  const ApplicationListStack = isVerticalList ? Stack : HorizontalApplicationListStack;
  return (
    <ApplicationListStack spacing={1} direction={isVerticalList ? 'column' : 'row'}>
      {props.applications.map(function (app, index, array) {
        return (
          <Fragment key={app.id}>
            <Link to={`/apps/${app.id}`}>
              <Box sx={{ px: 2 }}>
                <ApplicationListItem
                  application={app}
                  direction={isVerticalList ? 'horizontal' : 'vertical'}
                  hideDescription={!isVerticalList}
                />
              </Box>
            </Link>
            {isVerticalList && index < array.length - 1 ? <Divider /> : null}
          </Fragment>
        );
      })}
    </ApplicationListStack>
  );
}
