import Link from '@mui/material/Link';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import React from 'react';
import { FormattedMessage } from 'react-intl';

export type AuthorSocialListItemProps = {
  icon: React.ReactNode;
  href?: string | null;
  translationId: string;
};

export function AuthorSocialListItem(props: AuthorSocialListItemProps) {
  if (!props.href) return null;
  return (
    <Typography variant="caption">
      <Stack direction="row" spacing={1} sx={{ alignItems: 'center' }}>
        {props.icon}
        <Link href={props.href} target="_blank">
          <FormattedMessage id={props.translationId} />
        </Link>
      </Stack>
    </Typography>
  );
}
