import { Application } from '../types';
import { DataWrapped, IDatabaseService } from './IDatabaseService';

class ApplicationService implements IDatabaseService<Application> {
  private getDatabase(): Promise<Application[]> {
    return fetch('/api/applications.json')
      .then((response) => response.json() as Promise<DataWrapped<Application[]>>)
      .then(({ data }) => {
        for (const item of data) {
          item.createdAt = new Date(item.createdAt);
          item.updatedAt = new Date(item.updatedAt);
        }
        return data;
      });
  }

  async findAll(): Promise<Application[]> {
    const db = await this.getDatabase();
    return db
      .filter((app) => app.isAvailable)
      .sort(function (a1, a2) {
        return a2.createdAt.getTime() - a1.createdAt.getTime();
      });
  }

  async findAllByAuthor(authorId: string): Promise<Application[]> {
    const db = await this.findAll();
    return db.filter((app) => app.authorId === authorId);
  }

  async findOne(id: string): Promise<Application | null> {
    const db = await this.getDatabase();
    return db.find((app) => app.id === id) || null;
  }
}

const service = new ApplicationService();
export default service;
