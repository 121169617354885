import Container from '@mui/material/Container';
import { PropsWithChildren } from 'react';

export function AppContainer(props: PropsWithChildren<{}>) {
  return (
    <Container maxWidth="sm" sx={{ px: 0, paddingTop: 0, paddingBottom: 2 }}>
      {props.children}
    </Container>
  );
}
