import { Author } from '../types';
import { DataWrapped, IDatabaseService } from './IDatabaseService';

class AuthorService implements IDatabaseService<Author> {
  private getDatabase(): Promise<Author[]> {
    return fetch('/api/authors.json')
      .then((response) => response.json() as Promise<DataWrapped<Author[]>>)
      .then(({ data }) => {
        for (const item of data) {
          item.createdAt = new Date(item.createdAt);
          item.updatedAt = new Date(item.updatedAt);
        }
        return data;
      });
  }

  findAll(): Promise<Author[]> {
    return this.getDatabase();
  }

  async findOne(id: string): Promise<Author | null> {
    const db = await this.getDatabase();
    return db.find((author) => author.id === id) || null;
  }
}

const service = new AuthorService();
export default service;
