import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import { Author } from '../../types';
import { AuthorItem } from './AuthorItem';
import { AuthorSocialList } from './AuthorSocialList';

export type AuthorDetailsProps = {
  author?: Author | null;
};

export function AuthorDetails(props: AuthorDetailsProps) {
  if (!props.author) return null;
  return (
    <Stack>
      <AuthorItem author={props.author} />
      <Typography variant="caption" sx={{ my: 1 }}>
        {props.author.bio}
      </Typography>
      <AuthorSocialList author={props.author} />
    </Stack>
  );
}
